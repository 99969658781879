<template>
	<v-card>
		<v-toolbar color="primary">
			<v-toolbar-title class="white--text"
				>Detalles incidencia Nº {{ idIncidencia }}</v-toolbar-title
			>
			<v-spacer></v-spacer>
			<v-btn color="white" icon @click="$emit('close')">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>
		<v-container fluid>
			<v-row>
				<v-col cols="12" md="6" style="position: relative">
					<div class="sticky py-4">
						<v-row>
							<v-col cols="12" md="4">
								<v-text-field
									hide-details
									outlined
									readonly
									:value="data.Identidad"
									label="DNI/CIF/NIE"
								/>
							</v-col>
							<v-col cols="12" md="4" v-if="!data.RazonSocial">
								<v-text-field
									hide-details
									outlined
									readonly
									:value="
										[data.Nombre, data.Apellido1, data.Apellido2]
											.filter((v) => !!v)
											.join(' ')
									"
									label="Nombre Completo"
								/>
							</v-col>
							<v-col cols="12" md="4" v-else>
								<v-text-field
									hide-details
									outlined
									readonly
									:value="data.RazonSocial"
									label="Razón social"
								/>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field
									hide-details
									outlined
									readonly
									:value="data.CodPostal"
									label="Código Postal"
								/>
							</v-col>
							<v-col cols="12" md="4">
								<v-text-field
									hide-details
									outlined
									readonly
									:value="data.cupsCliente"
									label="Cups"
								/>
							</v-col>
							<v-col cols="12" md="4" v-if="data.Representante">
								<v-text-field
									hide-details
									outlined
									readonly
									:value="data.IdentificadorRep"
									label="DNI Representante"
								/>
							</v-col>
							<v-col cols="12" md="4" v-if="data.Representante">
								<v-text-field
									hide-details
									outlined
									readonly
									:value="data.Representante"
									label="Representante"
								/>
							</v-col>
						</v-row>

						<text-editor class="my-6" v-model="msg" />

						<v-radio-group
							v-model="estado"
							row
							:disabled="data.estado == 'Cerrada'"
						>
							<v-radio label="Abierta" value="Abierta"></v-radio>
							<v-radio label="Cerrada" value="Cerrada"></v-radio>
						</v-radio-group>

						<div class="d-flex">
							<v-spacer />
							<v-btn
								outlined
								color="primary"
								@click="send"
								:disabled="data.estado == 'Cerrada'"
							>
								<v-icon left>mdi-content-save</v-icon>
								Guardar y enviar
							</v-btn>
						</div>
					</div>
				</v-col>
				<v-col cols="12" md="6" style="height: 100%; overflow-y: auto">
					<v-btn small @click="getMessages" color="primary">
						<v-icon small left>mdi-reload</v-icon>
						Recargar
					</v-btn>
					<v-timeline dense>
						<v-timeline-item>
							<template v-slot:icon>
								<v-avatar color="success" size="40" />
							</template>
							<div class="d-flex">
								<v-chip outlined label color="success">
									<v-icon left>mdi-check</v-icon>
									Abierta
								</v-chip>
							</div>
						</v-timeline-item>
						<v-slide-x-transition group>
						<v-timeline-item v-for="(message, i) in messages" :key="i" small>
							<v-card>
								<v-card-subtitle class="py-2">
									{{ message.nombre }} ({{message.nombreAgente}})
									<v-icon
										small
										color="primary"
										v-if="
											JSON.parse(
												decode64($store.getters.getToken.split('.')[1])
											).idUsuario == message.idUsuario
										"
										>mdi-account</v-icon
									>
									-
									{{ new Date(Number(message.fecha)).toLocaleString() }}
								</v-card-subtitle>
								<div class="px-4 py-1" v-html="message.mensaje" />
							</v-card>
						</v-timeline-item>
						</v-slide-x-transition>
						<v-timeline-item v-if="data.estado == 'Cerrada'">
							<template v-slot:icon>
								<v-avatar color="error" size="40" />
							</template>
							<div class="d-flex">
								<v-chip class="" outlined label color="error">
									<v-icon left>mdi-close</v-icon>
									Cerrada
								</v-chip>
							</div>
						</v-timeline-item>
						<v-btn small @click="getMessages" color="primary">
						<v-icon small left>mdi-reload</v-icon>
						Recargar
					</v-btn>
					</v-timeline>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import { parseDate, decode64 } from "@/utils/index.js";

export default {
	components: {
		TextEditor: () => import("@/components/TextEditor.vue"),
	},
	props: {
		idIncidencia: String,
	},
	data() {
		return {
			msg: "",
			messages: [],
			data: {},
			estado: "",
		};
	},
	methods: {
		parseDate,
		decode64,
		getMessages() {
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/mensajesIncidencias.php`,
				params: {
					token: this.$store.getters.getToken,
					idIncidencia: this.idIncidencia,
				},
			})
				.then(({ data }) => {
					this.messages = data;
					this.messages.unshift({
						idUsuario: this.data.idUsuario,
						fecha: this.data.fecha,
						mensaje: this.data.mensaje,
						nombre: this.data.nombre,
						nombreAgente: this.data.nombreAgente
					});
				})
				.catch((err) => {
					console.error(err);
				});
		},
		send() {
			let { idUsuario } = JSON.parse(
				decode64(this.$store.getters.getToken.split(".")[1])
			);
			axios({
				method: "POST",
				url: `${process.env.VUE_APP_API_URL}/mensajesIncidencias.php`,
				data: {
					token: this.$store.getters.getToken,
					idIncidencia: this.idIncidencia,
					idUsuario,
					mensaje: this.msg,
					cupsCliente: this.cups,
					idCliente: this.data.idCliente,
					fecha: new Date().getTime(),
					estado: this.estado,
				},
			})
				.then(({ data }) => {
					if (this.data.estado != this.estado) {
						this.data.estado = this.estado;
						this.$emit("reload");
						this.$root.$emit('getAlerts');
					}
					this.msg = "";
					this.getMessages();
				})
				.catch((err) => console.error(err));
		},
	},
	mounted() {
		axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/incidencias.php`,
			params: {
				token: this.$store.getters.getToken,
				idIncidencia: this.idIncidencia,
			},
		}).then(({ data }) => {
			this.data = data;
			this.estado = data.estado;
		});
		this.getMessages();

		setInterval(() => {
			this.getMessages();
		}, 30 * 1000);
	},
};
</script>

<style>
.sticky {
	position: sticky;
	top: 0;
}
</style>